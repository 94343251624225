
import { Options, Vue } from 'vue-class-component';
import Myself from '@/components/Myself.vue';

@Options({
  components: {
    Myself
  }
})
export default class Team extends Vue {}
