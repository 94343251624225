
  import { Options, Vue } from 'vue-class-component';
  import Bubbles from '@/components/Bubbles.vue';

  @Options({
    components: {
      Bubbles
    }
  })
  export default class Generate extends Vue {
    get loading(): boolean {
      return this.$store.getters.loading;
    }

    generate(): void {
      this.$store.dispatch('generateImage');
    }

    get imageSrc(): string {
      return this.$store.getters.image;
    }
  }
