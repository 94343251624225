
import { Options, Vue } from 'vue-class-component';
import Generate from '@/components/Generate.vue';

@Options({
  components: {
    Generate
  }
})
export default class Generator extends Vue {}
